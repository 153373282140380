import React from "react";
import axios from "axios";
import "antd/dist/antd.css";
import Reaptcha from "reaptcha";
import { Form, Input, Button } from "antd";
import * as Constants from "../utils/constants";

class Contact extends React.Component<
  { includeTitle: boolean },
  {
    isLoading: boolean;
    mailSent: any;
    error: any;
    name: string;
    surname: string;
    email: string;
    phone: string;
    comments: string;
    captchaVerified: boolean;
    submitButtonEnabled: boolean;
    emailStatusMessage: string;
    emailStatusMessageVisible: boolean;
  }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      mailSent: null,
      error: null,
      name: "",
      surname: "",
      email: "",
      phone: "",
      comments: "",
      captchaVerified: false,
      submitButtonEnabled: false,
      emailStatusMessage: "",
      emailStatusMessageVisible: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCaptchaVerify = (recaptchaResponse: any) => {
    this.setState({
      captchaVerified: true,
      submitButtonEnabled: true,
    });
  };

  handleInput = (e: any) => {
    if (e.target.name !== "captcha") {
      this.setState<never>({
        [e.target.name]: e.target.value,
      });
    }
  };

  onFinish = (values: any) => {
    // Disable button immediately to avoid triggering the same email several times
    this.setState({
      submitButtonEnabled: false,
    });

    axios({
      method: "post",
      url: `${Constants.emailApiPath}`,
      headers: { "content-type": "application/json" },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
          emailStatusMessage:
            "Mensaje enviado correctamente, te contactaremos a la brevedad. Muchas gracias!",
          emailStatusMessageVisible: true,
        });
      })
      .catch((error) => {
        this.setState({ error: error.message, submitButtonEnabled: true }); // re-enable button to allow retry in case of errors
      });
  };

  onFinishFailed = (errorInfo: any) => {
    this.setState({
      emailStatusMessage: `Completa los campos requeridos`,
      emailStatusMessageVisible: true,
    });
  };

  render() {
    return (
      <div
        className="container-fluid"
        style={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
          marginTop: "2rem",
          marginBottom: "2rem",
          backgroundColor: Constants.ternaryColor,
          boxShadow: this.props.includeTitle
            ? "rgb(0, 0, 0, 0.16) 0px 3px 4px 0px"
            : "none",
        }}
      >
        {this.props.includeTitle ? (
          <div style={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
            <h3
              style={{
                fontFamily: "NeutraText-BookSC, Neutra Text",
                letterSpacing: "3px",
                textAlign: "left",
              }}
            >
              <b>Escribinos</b>
            </h3>
            <p style={{ textAlign: "left" }}>Armamos tu proyecto a medida</p>
          </div>
        ) : null}
        <div className="row justify-content-center">
          <Form
            className="col-10"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              className="form-field"
              name="name"
              rules={[{ required: true, message: "Ingresa tu nombre" }]}
            >
              <Input
                placeholder="Nombre"
                name="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-field"
              name="surname"
              rules={[{ required: true, message: "Ingresa tu apellido" }]}
            >
              <Input
                placeholder="Apellido"
                name="surname"
                onChange={(e) => this.setState({ surname: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-field"
              name="email"
              rules={[{ required: true, message: "Ingresa tu e-mail" }]}
            >
              <Input
                type="email"
                placeholder="E-mail"
                name="email"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-field"
              name="phone"
              rules={[{ required: true, message: "Ingresa tu teléfono" }]}
            >
              <Input
                type="phone"
                placeholder="Teléfono"
                name="phone"
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-field"
              name="comments"
              rules={[{ required: true, message: "Ingresa un mensaje" }]}
            >
              <Input.TextArea
                placeholder="Escribí tu mensaje"
                rows={7}
                name="comments"
                onChange={(e) => this.setState({ comments: e.target.value })}
              />
            </Form.Item>
            <div className="custom-differential-wrapper">
              <Form.Item
                className="captcha-button"
                name="captcha"
                style={{ display: "inline-block" }}
              >
                <div className="text-center">
                  <Reaptcha
                    sitekey={Constants.recaptchaPublicKey}
                    onVerify={this.onCaptchaVerify}
                  />
                </div>
              </Form.Item>
            </div>
            <div className="custom-differential-wrapper">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={!this.state.submitButtonEnabled}
                >
                  Enviar
                </Button>
              </Form.Item>
            </div>
          </Form>
          {this.state.emailStatusMessageVisible ? (
            <div className="ant-form ant-form-horizontal col-10">
              {this.state.emailStatusMessage}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Contact;
