import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MyContext } from "../contexts/MyContext";
// Importing the Login & Register Component
import Login from "./Login";
// import Register from "./Register"

// Define the base URL
const Axios = axios.create({
  baseURL: "https://studionanami.com/login_api/",
});

function Stats() {

  const [statsTable, setStatsTable] = useState("");

  useEffect(() => {
    if (!statsTable) {
      getStatsTable();
    }
  });

  const getStatsTable = async () => {
    const loginToken = localStorage.getItem("loginToken");

    // If inside the local-storage has the JWT token
    if (loginToken) {
      // Adding JWT token to axios default header
      Axios.defaults.headers.common["Authorization"] = "bearer " + loginToken;
      // Fetching the user information
      const data = await Axios.post("get_feed_stats.php");
      // @ts-ignore
      setStatsTable(data.data);
    }
  };

  // @ts-ignore
  const { rootState, logoutUser } = useContext(MyContext);
  const { isAuth, theUser /*, showLogin*/ } = rootState;
  // If user Logged in
  if (isAuth) {
    return (
      <div className="userInfo">
        <div className="_img"><span role="img" aria-label="User Image">👦</span></div>
        <h1>{theUser.name}</h1>
        <div className="_email"><span>{theUser.email}</span></div>
        <div style={{ backgroundColor: "white" }} dangerouslySetInnerHTML={{ __html: statsTable }}></div>
        <button onClick={logoutUser}>Logout</button>
      </div>
    );
  }
  // Showing Login Or Register Page According to the condition
  // else if (showLogin) {
  else {
    return <Login />;
  }
  // else {
  //   return <Register />;
  // }

}

export default Stats;