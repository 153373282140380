import React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../utils/constants";

class HeaderLogo extends React.Component {
  render() {
    return (
      <div className="nav-item">
        {/* The "mx-auto Bootstrap class centers the image" */}
        <Link className="navbar-brand mx-auto" to="/">
          <img
            height="55px"
            src={Constants.headerLogo}
            alt={Constants.headerLogo}
          ></img>
        </Link>
      </div>
    );
  }
}

export default HeaderLogo;
