import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../../components/Home";
// import { Spin } from "antd";
import Footer from "../Footer";
import MyContextProvider from "../../contexts/MyContext";
import Login from "../Login";
import Stats from "../../components/Stats";
import * as Constants from "../../utils/constants";

class App extends React.Component<{}, {}> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    // Workaround to avoid blocking direct load from other sections. It basically overrides the assetsFinishedLoading value if the app is not being loaded from the homepage
    // TODO: final a more general approach
    const path = window.location.pathname;
    if (path !== "/") {
      this.assetsFinishedLoading();
    }

    // call react-papaparse method:
    // readRemoteFile(Constants.spreadsheetSharedUrl, {
    //   header: true, // Used to retrieve data as objects with headers as keys

    //   // TODO: run tests to use "step" config in order to use pagination to retrieve items in batches (check https://react-papaparse.js.org/docs#remote-files)

    //   // step: (results: any, parser: any) => {
    //   //   console.log("Row data:", results.data);
    //   //   console.log("Row errors:", results.errors);
    //   // },
    //   complete: (results: any) => {
    //     this.setState({ items: results.data, isLoading: false });
    //   },
    //   error: (error: any) => this.setState({ error: error }),
    // });

    // axios
    //   .get(Constants.spreadsheetSharedUrl)
    //   .then((res: any) => {
    //     this.setState({ items: res.data, isLoading: false });
    //   })
    //   .catch((err: any) => {
    //     this.setState({ error: err });
    //   });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  assetsFinishedLoading = () => {
    this.setState({
      assetsFinishedLoading: true,
    });
  }

  render() {
    const appBody = (visibility: string) => (
      <MyContextProvider>
        <Router basename="/">
          <div className="App" style={{ display: visibility }}>
            <Header />
            <Switch>
              {/* Empty route for home */}
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/stats">
                <Stats />
              </Route>
            </Switch>
            <Footer />
          </div>
        </Router>
      </MyContextProvider>
    );

    return (
      <div style={{ backgroundColor: Constants.secondaryColor }}>
        {appBody("block")}
      </div>
    );
  }
}

export default App;
