import React from "react";

class Footer extends React.Component {
  private currentYear = new Date().getFullYear();
  render() {
    return (
      <div>
        <div
          className="container-fluid"
          style={{
            fontSize: "0.8rem",
            fontWeight: "bold",
            paddingTop: "1.4em",
            paddingBottom: "1.4em",
            backgroundColor: "white",
            color: "grey",
            letterSpacing: "3px",
          }}
        >
          <div className="row">
            <div className="col mx-auto">
              &copy; {this.currentYear} STUDIO NANAMI. TODOS LOS DERECHOS
              RESERVADOS.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
