// TODO: CHECK POSSIBILITY TO UNIFY WITH MANIFEST.JSON?

// TODO: improve workflow to minimize risk of errors/omissions

////////////////////////////////////////////////////////////////////////////
// cPanel credentials for gsheets in https://script.google.com/d/1J7N-EvSdJY4C9FpQE2MNXBAA5XD5j-T2sSjazwn0aOPvtdAgfbCqwT_P/edit?usp=drive_web&folder=0AOKropr3SwijUk9PVA&splash=yes
// Google Apps Script ID: 1J7N-EvSdJY4C9FpQE2MNXBAA5XD5j-T2sSjazwn0aOPvtdAgfbCqwT_P

//  Also remember to:
//  - Update Name, Content and Title in index.html file
//  - Update name in package.json and package-lock.json
//  - Include .htaccess file
//  - Create email (e.g. info@asd.com) on hosting platform
//  - Update access_token in handle_mercadopago.php
//  - Update email data in send_email.php
////////////////////////////////////////////////////////////////////////////

// Studio Nanami data
export const studioLink = "https://www.studionanami.com";
export const studioVisibleLink = "www.studionanami.com";
export const copyrightText = "©2020 Studio Nanami - ";

// Endpoints data
export const emailApiPath = "https://studionanami.com/send_email.php";

// Accounts data
export const websiteUrl = "studionanami.com";
export const recaptchaPublicKey = "6LetGN0ZAAAAAHleoy5sUq6O3xLVNhYs32k-7Wvl";

// Template data
// IMPORTANT: componentName must always be capitalized
export const sections = [
  {
    visibleName: "Inicio",
    componentName: "Start",
    path: "",
  },
  {
    visibleName: "Quiénes Somos",
    componentName: "About",
    path: "quienes_somos",
  },
  {
    visibleName: "Nuestros Productos",
    componentName: "ProductsHome",
    path: "nuestros_productos",
  },
  {
    visibleName: "Contactanos",
    componentName: "Contact",
    path: "contactanos",
  },
];
export const productCategories = [
  {
    name: "Granola",
    image: require("../images/product_categories/product_categories_1.jpg"),
  },
  {
    name: "Frutos Secos",
    image: require("../images/product_categories/product_categories_2.jpg"),
  },
  {
    name: "Mix Frutos Secos",
    image: require("../images/product_categories/product_categories_3.jpg"),
  },
  {
    name: "Snack",
    image: require("../images/product_categories/product_categories_4.jpg"),
  },
  {
    name: "Semillas",
    image: require("../images/product_categories/product_categories_5.jpg"),
  },
];
export const messagesForHomepageGrid = ["COMER BIEN PARA VIVIR MEJOR."];
export const isLogoCentered: boolean = true;
// TODO: define real links and/or think of a better approach
export const cartLink = "#";
export const allProductsLink = "Productos";

// Search bar data
export const showSearchBar: boolean = false;

// WhatsApp data
const whatsAppPhone = "541165779592";
const whatsAppDefaultMessage =
  "Hola! Me gustaría hacerles una consulta sobre sus productos";
export const whatsAppLink = `https://wa.me/${whatsAppPhone}?text=${encodeURIComponent(
  whatsAppDefaultMessage
)}`;

// Email data
export const contactEmail = "info@studionanami.com";
export const contactEmailConfirmationMessage =
  "Gracias por contactarnos! Te responderemos a la brevedad";

// General design (ALSO REPLACE VARS IN index.css!!)
export const primaryColor = "#EAF5F5";
export const opacity = 1;
export const secondaryColor = "#008080";
export const ternaryColor = "#BEF2ED";
export const highlightsBackgroundColor = "rgba(227,126,140,1)";
export const topMessageHeight = "0px"; // Used to define top message height, and also for header distance from top and for large images carousel distance from top

// Several logos and images
export const carouselImages = [
  require("../images/carousel_1.png"),
  require("../images/carousel_2.png"),
];
export const spinnerImage = require("../images/spinner_carga-05-05.png");
export const headerLogo = require("../images/LOGO_NANAMI.svg");
export const igLogo = require("../images/instagram_logo_blanco.svg");
export const fbLogo = require("../images/facebook_logo_blanco.svg");
export const waLogo = require("../images/WHATSAPP_logo_blanco.svg");
export const blackLogo = require("../images/logo_negro.svg");
export const firstImage = require("../images/Imagen_1_texto@2x.png");
export const secondImage = require("../images/Imagen_2@2x.png");
export const thirdImage = require("../images/Imagen_3@2x.png");
export const fourthImage = require("../images/Imagen_4@2x.png");
export const firstImageDesktop = require("../images/Imagen_1_desk.png");
export const secondImageDesktop = require("../images/Imagen_2_desk.png");
export const thirdImageDesktop = require("../images/Imagen_3_desk.png");
export const fourthImageDesktop = require("../images/Imagen_4_desk.png");
export const imagesAndTextArray = [
  {
    image: require("../images/Grupo 847.svg"),
    title: "FÁCILMENTE GESTIONABLE",
    paragraph: "Actualizá tus productos, precios y stock en segundos.",
  },
  {
    image: require("../images/Grupo 848.svg"),
    title: "DISEÑO PERSONALIZADO",
    paragraph:
      "Nuestro equipo de diseñadores profesionales está al servicio de tus ideas.",
  },
  {
    image: require("../images/Grupo 849.svg"),
    title: "INTEGRACIÓN CON REDES Y SERVICIOS EXTERNOS",
    paragraph:
      "Conectá tu página web con Facebook, Instagram, WhatsApp y Mercado Pago.",
  },
  {
    image: require("../images/Grupo 850.svg"),
    title: "ASESORAMIENTO COMERCIAL PERSONALIZADO",
    paragraph:
      "Un asesor exclusivo te guía hacia el proyecto que mejor se ajuste a tus objetivos.",
  },
];
// Also update images for manifest.json

/* PowerShell commands to:
1) Create timestamp
2) Zip all files in folder after npm run build (it's easier to upload files to server this way)
3) Upload zip to server (provides source and destination as cli params)

$timestamp = Get-Date -Format "yyyy_MM_dd_HH_mm_ss"
compress-archive -path 'C:\Users\Bruno\Documents\Websites\Studio Nanami\build\*' -Update -destinationpath "C:\Users\Bruno\Desktop\build_$timestamp.zip" -compressionlevel optimal
node 'C:\Users\Bruno\Documents\Websites\Amigurumi\upload_files (local test).js' "C:/Users/Bruno/Desktop/build_$timestamp.zip" "public_html/studionanami/"

*/
