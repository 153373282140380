import React from "react";
import HeaderLogo from "./HeaderLogo";
import * as Constants from "../utils/constants";

class Header extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    let header: any;

    header = (
      <div className="col">
        <ul className="navbar-nav mx-auto">
          <HeaderLogo />
        </ul>
      </div>
    );

    return (
      <nav
        className="navbar fixed-top navbar-dark justify-content-md-center justify-content-start"
        style={{
          color: Constants.secondaryColor,
          backgroundColor: Constants.primaryColor,
          opacity: Constants.opacity,
          width: "100%",
          top: Constants.topMessageHeight,
        }}
      >
        <div
          className="container-fluid align-items-center"
          style={{ justifyContent: "unset" }}
        >
          {header}
        </div>
      </nav>
    );
  }
}

export default Header;
