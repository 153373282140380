import React from "react";
import MediaQuery from "react-responsive";
import Contact from "./Contact";
import * as Constants from "../utils/constants";

class Home extends React.Component<{}, {}> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private imageContainer = (image: string, altText: string) => {
    return (
      <div
        className="row"
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
      >
        <div className="col-10 offset-1">
          <img
            src={image}
            alt={altText}
            className="img-responsive"
            width="100%"
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <MediaQuery maxWidth={700}>
          <div className="container" style={{ paddingTop: "120px" }}>
            {this.imageContainer(
              Constants.firstImage,
              "Incorporá una nueva plataforma para ampliar el alcance de tu actividad comercial"
            )}
            {Constants.imagesAndTextArray.map((imgAndText, index) => (
              <div
                key={index}
                className="row"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <div className="col-4 offset-1">
                  <img src={imgAndText.image} alt={imgAndText.image} />
                </div>
                <div
                  className="col-5 text-left"
                  style={{ color: Constants.primaryColor }}
                >
                  <b>{imgAndText.title}</b>
                  <br />
                  <span style={{ fontSize: "0.8rem" }}>
                    {imgAndText.paragraph}
                  </span>
                </div>
              </div>
            ))}
            {this.imageContainer(
              Constants.secondImage,
              "Impulsá tus ventas con diversas opciones de cobro y promociones bancarias. EFECTIVO, TRANSFERENCIA BANCARIA, MERCADOPAGO"
            )}
            {this.imageContainer(
              Constants.thirdImage,
              "Incrementá el tráfico en tus redes"
            )}
            {this.imageContainer(
              Constants.fourthImage,
              "Autogestiona tu propia página web de manera fácil y rápida mediante una planilla"
            )}
            <Contact includeTitle={true} />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <div className="container" style={{ paddingTop: "120px" }}>
            {this.imageContainer(
              Constants.firstImageDesktop,
              "Incorporá una nueva plataforma para ampliar el alcance de tu actividad comercial"
            )}
            <div
              className="row"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            >
              <div className="col-10 offset-1">
                <div className="row">
                  {Constants.imagesAndTextArray.map((imgAndText, index) => (
                    <div key={index} className="col-3 text-left">
                      <img src={imgAndText.image} alt={imgAndText.image} />
                    </div>
                  ))}
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  {Constants.imagesAndTextArray.map((imgAndText, index) => (
                    <div
                      key={index}
                      className="col-3 text-left"
                      style={{ color: Constants.primaryColor }}
                    >
                      <b>{imgAndText.title}</b>
                      <br />
                      <span style={{ fontSize: "0.8rem" }}>
                        {imgAndText.paragraph}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {this.imageContainer(
              Constants.secondImageDesktop,
              "Impulsá tus ventas con diversas opciones de cobro y promociones bancarias. EFECTIVO, TRANSFERENCIA BANCARIA, MERCADOPAGO"
            )}
            {this.imageContainer(
              Constants.thirdImageDesktop,
              "Incrementá el tráfico en tus redes"
            )}
            {this.imageContainer(
              Constants.fourthImageDesktop,
              "Autogestiona tu propia página web de manera fácil y rápida mediante una planilla"
            )}
            <div
              className="row"
              style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
            >
              <div
                className="col-10 offset-1"
                style={{
                  backgroundColor: Constants.ternaryColor,
                  boxShadow: "rgb(0, 0, 0, 0.16) 0px 3px 4px 0px",
                }}
              >
                <div className="row">
                  <div className="col-3 offset-1">
                    <div
                      className="container-fluid"
                      style={{
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        marginTop: "2rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <div>
                        <h3
                          style={{
                            fontFamily: "NeutraText-BookSC, Neutra Text",
                            letterSpacing: "3px",
                            textAlign: "left",
                          }}
                        >
                          <b>Escribinos</b>
                        </h3>
                        <p style={{ textAlign: "left" }}>
                          Armamos tu proyecto a medida
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <Contact includeTitle={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Home;
